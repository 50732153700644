<script lang="ts" setup>
import type { ILeaseSummaryField, ILeaseSummaryFieldValue } from '@register'
import dayjs from 'dayjs'
import { leaseStatusObject } from '~/constants/LeaseStatus'

interface Props {
  field: ILeaseSummaryField
  value: ILeaseSummaryFieldValue
}

defineProps<Props>()

const { dateFormat } = useCurrentLease()
</script>

<template>
  <div class="flex-auto text-sm print:bg-white">
    <RegisterSummaryFieldOptionExerciseDetails
      v-if="field.value?.value && field.id === 'OptionExerciseDetails'"
      :field="field"
      :value="value"
    />
    <RegisterSummaryFieldRentIncreaseTable
      v-else-if="field && field.id.includes('Table')"
      :value="value"
      :field="field"
    />
    <RegisterSummaryFieldPropertyAddress
      v-else-if="field.value?.value && field.id === 'PropertyAddress'"
      :field="field"
      :value="value"
    />
    <div v-else-if="field.id === 'Lease__Status'">
      {{
        leaseStatusObject[value?.value as keyof typeof leaseStatusObject].label
      }}
    </div>

    <div
      v-else-if="field.value?.value && field.id.includes('Date')"
      class="flex-auto text-sm print:bg-white"
    >
      <time>
        {{
          dayjs(value.value).isValid()
            ? dayjs(value.value).format(dateFormat)
            : value.value
        }}
      </time>
    </div>
    <div v-else class="flex-auto text-sm print:bg-white">
      <span class="relative flex flex-col whitespace-pre-line print:bg-white">
        {{ value.value ?? 'N/A' }}
      </span>
    </div>
  </div>
</template>
